.window {
  position: absolute;
  top: 100;
  left: 0;
  /* width: 550px; */
  /* height: 460px; */
  border: 1px solid #808080;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #f8ecd4;
  padding-bottom: 10px;
}

.window-titlebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid #808080;
  background-color: #CCCCCC;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: inset 0em 0.2em 0em 0 rgba(255,255,255,0.5), inset -0em -0.2em 0em 0 rgba(0,0,0,0.2);  
  cursor: move;
}

.window-title {
  margin-left: 10px;
  flex-grow: 1;
  user-select: none;
  font-family: 'DotGothic16', sans-serif;
  font-size: 1.2em; 
  font-weight: bold; 
}

.window-controls {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.window-controls button {
  width: 25px;
  height: 25px;
  border: 1px solid black;
  box-shadow: inset -0.2em -0.2em 0em 0 rgba(0,0,0,0.2), inset 0.2em 0.2em 0em 0 rgba(255,255,255,0.5);
  box-shadow:  0.2em 0.2em 0em 0 rgba(255,255,255,0.5), -0.2em -0.2em 0em 0 rgba(0,0,0,0.2),;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: black;
}

.window-controls button:hover {
  background-color: #ff5050;
}

.draggable-icon{
  text-align: center;
  font-family: 'DotGothic16', sans-serif;
  font-weight: bold;
}

.draggable-icon figcaption{
  color: white;
  /* https://owumaro.github.io/text-stroke-generator/ */
  text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px;
}

.window-content {
  position: absolute;
  top: 30px;
  left: 0px;
  right: 0px;
  bottom: 0;
  /* width: 95%; */
  /* height: calc(100% - 25px); */
  overflow: hidden;
  padding: 10px;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  font-size: large;
  /* outline: 5px solid #515151;
  outline-offset: -5px; */
}

.window-content button {
  width: 130px;
  height: 50px;
  margin-top: 10px;
  border-color: black;
  background-color: orange;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  color: black;
}

.window-content .disabled-button{
  background-color: lightgray;
  color: gray;
  border: 2px solid gray;
}

.window-content .disabled-button:hover{
  background-color: lightgray;
  color: gray;
  border: 2px solid gray;
}

.window-content button:hover {
  background-color: #f1f1f1;
}

.taskbar {
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  width: 100%;
  height: 35px;
  background: rgb(255,218,144);
  background: -moz-linear-gradient(90deg, rgba(255,218,144,1) 0%, rgba(254,205,133,1) 52%, rgba(252,166,101,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255,218,144,1) 0%, rgba(254,205,133,1) 52%, rgba(252,166,101,1) 100%);
  background: linear-gradient(90deg, rgba(255,218,144,1) 0%, rgba(254,205,133,1) 52%, rgba(252,166,101,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffda90",endColorstr="#fca665",GradientType=1);
  border-top: 1px solid #808080;
  display: flex;
  align-items: center;
  font-family: 'DotGothic16', sans-serif;
  font-size: 1.5em;
  /* font-weight: bold; */
}

.taskbar-os-name{
  margin-left: 10px;
}

.taskbar-devfest-logo{
  position: relative;
  transform: scale(70%);
  top: 27px;
  left: -20px;
  right: auto;
  bottom: auto;
}

.taskbar-countdown-timer-icon{
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 10px;  
  object-fit: contain;
}

.timer-text{
  position: relative;
  top: -0.3em;
}

.taskbar-default-timer{
  position: relative;
  top: 3.75em;
  right: -1.5em;
}

.taskbar-default-timer:hover + .taskbar-countdown-timer-tooltip{
  visibility: visible;
}

.taskbar-countdown-timer-tooltip {
	position: relative;
	background: #F0EAD6;
	border-radius: 0px;
  border: 2px solid black;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  top: 65px;
  text-align: center;
  visibility: hidden;
}

/* .taskbar-countdown-timer-tooltip:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 1.125em solid black;
	border-bottom-color: #F0EAD6;
	border-top: 0;
	margin-left: -1.125em;
	margin-top: -1.125em;
} */

.taskbar-countdown-timer {
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}

.desktop {
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("./Assets/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.desktop-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tracks-wrapper{
  overflow: hidden;
  text-align: left;
}

.track{
  display: flex;
  align-items: center;
  background-color: #f8ecd4;
  width: 100%;
  height: 100px;
  border: 1px solid black;
  font-family: 'EB Garamond', serif;
  font-style: italic;
  font-size: 3em;
  padding-left: 10px;
  white-space: nowrap;
}

#ar-vr {
  background-image: url("./Assets/Tracks/AR-VR.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-shadow: rgb(240, 152, 74) 5px 0px 0px, rgb(240, 152, 74) 4.90033px 0.993347px 0px, rgb(240, 152, 74) 4.60531px 1.94709px 0px, rgb(240, 152, 74) 4.12668px 2.82321px 0px, rgb(240, 152, 74) 3.48353px 3.58678px 0px, rgb(240, 152, 74) 2.70151px 4.20736px 0px, rgb(240, 152, 74) 1.81179px 4.6602px 0px, rgb(240, 152, 74) 0.849836px 4.92725px 0px, rgb(240, 152, 74) -0.145998px 4.99787px 0px, rgb(240, 152, 74) -1.13601px 4.86924px 0px, rgb(240, 152, 74) -2.08073px 4.54649px 0px, rgb(240, 152, 74) -2.94251px 4.04248px 0px, rgb(240, 152, 74) -3.68697px 3.37732px 0px, rgb(240, 152, 74) -4.28444px 2.57751px 0px, rgb(240, 152, 74) -4.71111px 1.67494px 0px, rgb(240, 152, 74) -4.94996px 0.7056px 0px, rgb(240, 152, 74) -4.99147px -0.291871px 0px, rgb(240, 152, 74) -4.83399px -1.27771px 0px, rgb(240, 152, 74) -4.48379px -2.2126px 0px, rgb(240, 152, 74) -3.95484px -3.05929px 0px, rgb(240, 152, 74) -3.26822px -3.78401px 0px, rgb(240, 152, 74) -2.4513px -4.35788px 0px, rgb(240, 152, 74) -1.53666px -4.75801px 0px, rgb(240, 152, 74) -0.560763px -4.96845px 0px, rgb(240, 152, 74) 0.437495px -4.98082px 0px, rgb(240, 152, 74) 1.41831px -4.79462px 0px, rgb(240, 152, 74) 2.34258px -4.41727px 0px, rgb(240, 152, 74) 3.17346px -3.86382px 0px, rgb(240, 152, 74) 3.87783px -3.15633px 0px, rgb(240, 152, 74) 4.4276px -2.32301px 0px, rgb(240, 152, 74) 4.80085px -1.39708px 0px, rgb(240, 152, 74) 4.98271px -0.415447px 0px;  
}
#design {
  background-image: url("./Assets/Tracks/Design.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-shadow: rgb(232, 196, 146) 5px 0px 0px, rgb(232, 196, 146) 4.90033px 0.993347px 0px, rgb(232, 196, 146) 4.60531px 1.94709px 0px, rgb(232, 196, 146) 4.12668px 2.82321px 0px, rgb(232, 196, 146) 3.48353px 3.58678px 0px, rgb(232, 196, 146) 2.70151px 4.20736px 0px, rgb(232, 196, 146) 1.81179px 4.6602px 0px, rgb(232, 196, 146) 0.849836px 4.92725px 0px, rgb(232, 196, 146) -0.145998px 4.99787px 0px, rgb(232, 196, 146) -1.13601px 4.86924px 0px, rgb(232, 196, 146) -2.08073px 4.54649px 0px, rgb(232, 196, 146) -2.94251px 4.04248px 0px, rgb(232, 196, 146) -3.68697px 3.37732px 0px, rgb(232, 196, 146) -4.28444px 2.57751px 0px, rgb(232, 196, 146) -4.71111px 1.67494px 0px, rgb(232, 196, 146) -4.94996px 0.7056px 0px, rgb(232, 196, 146) -4.99147px -0.291871px 0px, rgb(232, 196, 146) -4.83399px -1.27771px 0px, rgb(232, 196, 146) -4.48379px -2.2126px 0px, rgb(232, 196, 146) -3.95484px -3.05929px 0px, rgb(232, 196, 146) -3.26822px -3.78401px 0px, rgb(232, 196, 146) -2.4513px -4.35788px 0px, rgb(232, 196, 146) -1.53666px -4.75801px 0px, rgb(232, 196, 146) -0.560763px -4.96845px 0px, rgb(232, 196, 146) 0.437495px -4.98082px 0px, rgb(232, 196, 146) 1.41831px -4.79462px 0px, rgb(232, 196, 146) 2.34258px -4.41727px 0px, rgb(232, 196, 146) 3.17346px -3.86382px 0px, rgb(232, 196, 146) 3.87783px -3.15633px 0px, rgb(232, 196, 146) 4.4276px -2.32301px 0px, rgb(232, 196, 146) 4.80085px -1.39708px 0px, rgb(232, 196, 146) 4.98271px -0.415447px 0px;  
}
#ai-data {
  background-image: url("./Assets/Tracks/AI-Data.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-shadow: rgb(240, 234, 214) 5px 0px 0px, rgb(240, 234, 214) 4.90033px 0.993347px 0px, rgb(240, 234, 214) 4.60531px 1.94709px 0px, rgb(240, 234, 214) 4.12668px 2.82321px 0px, rgb(240, 234, 214) 3.48353px 3.58678px 0px, rgb(240, 234, 214) 2.70151px 4.20736px 0px, rgb(240, 234, 214) 1.81179px 4.6602px 0px, rgb(240, 234, 214) 0.849836px 4.92725px 0px, rgb(240, 234, 214) -0.145998px 4.99787px 0px, rgb(240, 234, 214) -1.13601px 4.86924px 0px, rgb(240, 234, 214) -2.08073px 4.54649px 0px, rgb(240, 234, 214) -2.94251px 4.04248px 0px, rgb(240, 234, 214) -3.68697px 3.37732px 0px, rgb(240, 234, 214) -4.28444px 2.57751px 0px, rgb(240, 234, 214) -4.71111px 1.67494px 0px, rgb(240, 234, 214) -4.94996px 0.7056px 0px, rgb(240, 234, 214) -4.99147px -0.291871px 0px, rgb(240, 234, 214) -4.83399px -1.27771px 0px, rgb(240, 234, 214) -4.48379px -2.2126px 0px, rgb(240, 234, 214) -3.95484px -3.05929px 0px, rgb(240, 234, 214) -3.26822px -3.78401px 0px, rgb(240, 234, 214) -2.4513px -4.35788px 0px, rgb(240, 234, 214) -1.53666px -4.75801px 0px, rgb(240, 234, 214) -0.560763px -4.96845px 0px, rgb(240, 234, 214) 0.437495px -4.98082px 0px, rgb(240, 234, 214) 1.41831px -4.79462px 0px, rgb(240, 234, 214) 2.34258px -4.41727px 0px, rgb(240, 234, 214) 3.17346px -3.86382px 0px, rgb(240, 234, 214) 3.87783px -3.15633px 0px, rgb(240, 234, 214) 4.4276px -2.32301px 0px, rgb(240, 234, 214) 4.80085px -1.39708px 0px, rgb(240, 234, 214) 4.98271px -0.415447px 0px;
}
#algorand {
  background-image: url("./Assets/Tracks/Algorand.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-shadow: rgb(144, 203, 236) 5px 0px 0px, rgb(144, 203, 236) 4.90033px 0.993347px 0px, rgb(144, 203, 236) 4.60531px 1.94709px 0px, rgb(144, 203, 236) 4.12668px 2.82321px 0px, rgb(144, 203, 236) 3.48353px 3.58678px 0px, rgb(144, 203, 236) 2.70151px 4.20736px 0px, rgb(144, 203, 236) 1.81179px 4.6602px 0px, rgb(144, 203, 236) 0.849836px 4.92725px 0px, rgb(144, 203, 236) -0.145998px 4.99787px 0px, rgb(144, 203, 236) -1.13601px 4.86924px 0px, rgb(144, 203, 236) -2.08073px 4.54649px 0px, rgb(144, 203, 236) -2.94251px 4.04248px 0px, rgb(144, 203, 236) -3.68697px 3.37732px 0px, rgb(144, 203, 236) -4.28444px 2.57751px 0px, rgb(144, 203, 236) -4.71111px 1.67494px 0px, rgb(144, 203, 236) -4.94996px 0.7056px 0px, rgb(144, 203, 236) -4.99147px -0.291871px 0px, rgb(144, 203, 236) -4.83399px -1.27771px 0px, rgb(144, 203, 236) -4.48379px -2.2126px 0px, rgb(144, 203, 236) -3.95484px -3.05929px 0px, rgb(144, 203, 236) -3.26822px -3.78401px 0px, rgb(144, 203, 236) -2.4513px -4.35788px 0px, rgb(144, 203, 236) -1.53666px -4.75801px 0px, rgb(144, 203, 236) -0.560763px -4.96845px 0px, rgb(144, 203, 236) 0.437495px -4.98082px 0px, rgb(144, 203, 236) 1.41831px -4.79462px 0px, rgb(144, 203, 236) 2.34258px -4.41727px 0px, rgb(144, 203, 236) 3.17346px -3.86382px 0px, rgb(144, 203, 236) 3.87783px -3.15633px 0px, rgb(144, 203, 236) 4.4276px -2.32301px 0px, rgb(144, 203, 236) 4.80085px -1.39708px 0px, rgb(144, 203, 236) 4.98271px -0.415447px 0px;
}
.locked{
  width: 100%;
  height: 300px; 
}

.blurred{
  width: 100%;
  height: 300px;
  background-image: url("./Assets/Locked/Judges.jpg");
  background-size: cover;
  background-repeat: repeat;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px)   
}

.lock-icon{
  position: relative;
  z-index: 1;
  top: 30px;
}

.blurred img{
  width: 100%;
  object-fit: contain;
}